.card-card {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-items: flex-start;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #8b6fd5;
}
.card-icon {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  background-color: #6a4cb9;
}
.card-icon1 {
  height: 30px;
  width: 30px;
  object-fit: cover;
}
.card-content {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.card-header {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  line-height: 36px;
}
.card-description {
  color: #c2c6cc;
  font-family: Poppins;
  line-height: 28px;
}

@media (max-width: 991px) {
  .card-root-class-name {
    flex: 0 0 auto;
    width: 100%;
  }
  .card-root-class-name1 {
    flex: 0 0 auto;
    width: 100%;
  }
  .card-root-class-name2 {
    flex: 0 0 auto;
    width: 100%;
  }
}
