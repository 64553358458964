.icon {
  width: 60px;
  height: auto;
}

.file-info {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 25px 25px;
}

.file-action-img {
  margin-left: 10px;
}
.animated-border {
  animation: animatedBorder 2s linear infinite;
}

.static-green-border {
  animation: none !important;
  border-color: rgba(0, 255, 0, 0.92) !important;
}

.home-get-started {
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}

.border-btn {
  border-color: aliceblue;
  background: green;
}

.button-text {
  color: #d8e87f;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.file-progress {
  background-color: rgba(180, 162, 241, 0.17);
  height: 100%;
  transition: width 0.2s ease-out;
  border-radius: 0 0 0px 25px;
  position: absolute;
  z-index: -1;
}

.file-info-container {
  width: 100%;
  height: 12%;
  border-radius: 0 0 25px 25px;
  position: relative;
  z-index: 1;
}

.half-border-radius {
  border-radius: 0 0 25px 25px;
}

.file-info {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 25px 25px;
  padding-left: 15px;
  padding-right: 15px;
}

.file-action-img {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.caption-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  align-self: center;
  text-align: center;
}

.convert-another-file {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #9dff7a;
  text-decoration: underline;
}

.icon-rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media Queries */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .icon {
    width: 60px;
  }
}

/* Medium devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .icon {
    width: 50px;
  }
}

/* Small devices (landscape phones, 576px to 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .icon {
    width: 40px;
  }
}

/* Extra small devices (portrait phones, below 576px) */
@media (max-width: 575px) {
  .icon {
    width: 30px;
  }
}
