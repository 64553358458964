.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #6a4cb9;
}
.home-section {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  border-color: #ffffff00;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  border-bottom-width: 1px;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.home-main {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gradient-text {
  background-image: linear-gradient(45deg, #e4d4fd 0%, #c5b4f7 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.home-heading {
  font-size: 64px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.home-caption {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-family: Poppins;
  line-height: 30px;
}
.home-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-get-started {
  display: flex;
  background-color: #80ff44;
}

.primary {
  background-image: linear-gradient(135deg, #9c82d4 0%, #8648bc 100%);
}

.home-text {
  color: rgb(254, 254, 254);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-get-started1 {
  display: flex;
  background-color: #2a2a2a;
}
.home-text01 {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-highlight {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatars {
  width: 115px;
  height: var(--dl-size-size-small);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
.home-image {
  left: 0px;
  position: absolute;
}
.home-image01 {
  left: var(--dl-space-space-twounits);
  position: absolute;
  object-fit: cover;
}
.home-image02 {
  left: var(--dl-space-space-fourunits);
  position: absolute;
  object-fit: cover;
}
.home-caption1 {
  color: rgb(255, 255, 255);
  font-family: Poppins;
  line-height: 24px;
}
.home-image03 {
  top: 150px;
  right: 0px;
  width: 650px;
  height: 900px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image04 {
  right: -146px;
  width: 100%;
  bottom: -22px;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.home-image05 {
  display: none;
}
.home-section1 {
  gap: var(--dl-space-space-sixunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.home-header1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text02 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.home-note {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image07 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image08 {
  object-fit: cover;
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-main1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-caption2 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading01 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-get-started2 {
  display: flex;
  background-color: #80ff44;
}
.home-text03 {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-note1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}
.home-image09 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image10 {
  object-fit: cover;
}
.home-content2 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-main2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-caption3 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading03 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-get-started3 {
  display: flex;
}

.gradient-bg {
  background-image: linear-gradient(135deg, #a78de3 0%, #7b44c5 100%);
}

.home-get-started3:hover {
  background-image: linear-gradient(135deg, #c5a7f3 0%, #a78de3 100%);
}
.home-text04 {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-note2 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image11 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image12 {
  object-fit: cover;
}
.home-content3 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-main3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-caption4 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading05 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-get-started4 {
  display: flex;
  background-color: #80ff44;
}
.home-text05 {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-section2 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  border-color: #51515a;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-top-width: 1px;
}
.home-header3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-left {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-right {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-cards {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-section3 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.home-section4 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
  background-color: #8b6fd5;
}
.home-content4 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-header4 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  text-align: center;
}
.home-heading08 {
  text-align: center;
}
.home-buttons1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-android {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(135deg, #b091e2 0%, #8f5bc6 100%);
}
.home-icon {
  width: 16px;
  object-fit: cover;
}
.home-text08 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-image13 {
  right: 0px;
  width: 470px;
  bottom: 0px;
  position: absolute;
  object-fit: cover;
}
.home-footer {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-content5 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-main-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-content6 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}
.home-main4 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-header5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-branding {
  width: 200px;
  object-fit: cover;
  align-self: center;
}
.home-text09 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  max-width: 260px;
  font-family: Poppins;
  line-height: 21px;
}
.home-socials {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.social-icon {
  max-width: 24px;
  cursor: pointer;
}

.icon-tooltip {
  visibility: hidden;
  width: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.icon-container:hover .icon-tooltip {
  visibility: visible;
  opacity: 1;
}

.home-link {
  display: contents;
}
.home-link1 {
  display: contents;
}
.home-link2 {
  display: contents;
}
.home-categories {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-category {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-category1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 175px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-links1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-copyright {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text23 {
  color: #c4c4c4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
.home-subscribe {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-main5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading09 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  max-width: 275px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  line-height: 30px;
}
.home-input-field {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
  background-color: #8b6fd5;
}
.home-textinput {
  flex: 1;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  outline-style: none;
  background-color: rgba(217, 217, 217, 0);
}
.home-buy {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #80ff44;
}
.home-text24 {
  color: rgb(12, 16, 12);
  display: none;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-text25 {
  color: rgb(12, 16, 12);
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}
.home-notice {
  color: #686868;
  font-size: 14px;
  max-width: 400px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: 21px;
}
.home-copyright1 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text28 {
  color: #c4c4c4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}
@media (max-width: 991px) {
  .home-section {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-hero {
    gap: var(--dl-space-space-fourunits);
    flex: 1;
    position: relative;
    max-width: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-content {
    position: relative;
    align-items: center;
  }
  .home-main {
    align-items: center;
  }
  .home-heading {
    text-align: center;
  }
  .home-caption {
    text-align: center;
  }
  .home-buttons {
    width: 100%;
  }
  .home-get-started {
    flex: 1;
  }
  .home-get-started1 {
    flex: 1;
  }
  .home-highlight {
    width: 100%;
    flex-direction: column;
  }
  .home-image03 {
    right: 0px;
    display: none;
    position: absolute;
    flex-direction: column;
  }
  .home-image05 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .home-image06 {
    width: 600px;
  }
  .home-text02 {
    text-align: center;
  }
  .home-section2 {
    gap: var(--dl-space-space-threeunits);
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home-header3 {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-cards {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-section3 {
    padding-top: 0px;
  }
  .home-content4 {
    align-items: center;
    flex-direction: column;
  }
  .home-buttons1 {
    width: 100%;
  }
  .home-android {
    flex: 1;
    justify-content: center;
  }
  .home-image13 {
    position: static;
  }
  .home-content5 {
    gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-copyright {
    display: none;
  }
  .home-copyright1 {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
/* Media Queries - Part 1 */

/* Medium devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .home-section {
    padding-left: 15px;
    padding-right: 15px;
  }
  .home-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .home-get-started,
  .home-get-started1 {
    width: 48%;
  }
  .home-highlight {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .home-image03 {
    display: none;
  }
  .home-image05 {
    display: flex;
    width: 100%;
  }
}

/* Media Queries - Part 2 */

/* Small devices (landscape phones, 576px to 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .home-section {
    padding-left: 15px;
    padding-right: 15px;
  }
  .home-hero,
  .home-content,
  .home-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-heading {
    font-size: 32px;
    text-align: center;
  }
  .home-caption {
    font-size: 16px;
  }
  .home-buttons {
    display: flex;
    flex-direction: column;
  }
  .home-get-started,
  .home-get-started1 {
    width: 100%;
  }
  .home-get-started {
    margin-bottom: 15px;
  }
  .home-highlight {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .home-image03 {
    display: none;
  }
  .home-image05 {
    display: flex;
    width: 100%;
  }
}

/* Media Queries - Part 4 */

/* Extra small devices (portrait phones, below 576px) */
@media (max-width: 575px) {
  .home-header {
    align-items: center;
  }
  .home-heading {
    max-width: 260px;
    font-size: 36px;
  }
  .home-caption {
    font-size: 14px;
  }
  .home-image06 {
    width: 320px;
  }
  .home-section1 {
    gap: var(--dl-space-space-threeunits);
  }
  .home-text02 {
    font-size: 28px;
  }
  .home-note,
  .home-note1,
  .home-note2 {
    flex-direction: column-reverse;
  }
  .home-left {
    gap: var(--dl-space-space-unit);
  }
  .home-section4 {
    padding-top: var(--dl-space-space-threeunits);
  }
  .home-header4 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-buttons1 {
    flex-direction: column;
  }
  .home-android {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-content6 {
    gap: var(--dl-space-space-twoandhalfunits);
    flex-direction: column;
  }
  .home-main4 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-categories {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
}

.home-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-contact-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.home-contact-icons {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.home-contact-icon {
  margin: 0 10px;
  cursor: pointer;
}

.home-contact-icon img {
  height: 40px;
}
