.convert-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #6a4cb9;
}

.convert-text1 {
  color: #5763f1;
  font-size: 6rem;
  font-style: normal;
  font-weight: 400;
}
.convert-text2 {
  color: #ededed;
  font-size: 6rem;
  font-style: normal;
  font-weight: 500;
}
.convert-text3 {
  color: #d8e87f;
  text-align: center;
  line-height: 4;
  font-family: Poppins;
}
.converter-parent-container {
  width: 700px;
  height: 400px;
  display: flex;
  flex-direction: column;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
  background-color: rgba(55, 84, 66, 0.04);
  font-family: Poppins;
  row-gap: 20px;
}
.converter-border-container {
  flex: 0 0 auto;
  width: 713px;
  height: 309px;
  display: flex;
  position: relative;
  align-items: center;
  border-color: rgba(231, 224, 224, 0.92);
  border-style: dotted;
  border-width: 2px;
  border-radius: 25px;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    to bottom right,
    rgba(106, 76, 185, 0.8),
    rgba(255, 255, 255, 0.1)
  );
}

.text-holder {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.converter-component-container {
  top: 0px;
  bottom: 10%;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.convert-icon {
  fill: #20b922;
  width: 57px;
  height: 52px;
  margin-top: 0px;
}
.status-text {
  color: #fdfdfd;
  font-size: 16px;
  margin-top: 20px;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.image-status {
}

.convert-get-started {
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  background-color: rgba(180, 162, 241, 0.17);
  position: relative;
  overflow: hidden;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}

.convert-get-started::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #59acef, #f26522, #59acef);
  background-size: 200% 200%;
  z-index: -1;
  opacity: 0;
}

.convert-get-started.animate-border::before {
  opacity: 1;
  animation: gradient 3s infinite;
}

.always-animate-border::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #a885ce, #7d5da9, #a885ce);
  background-size: 200% 200%;
  z-index: -1;
  animation: gradient 3s infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.convert-get-started.animate-border::before {
  opacity: 1;
  animation: gradient 3s infinite;
}

.convert-container-last {
  height: 200px;
}

/* Media Queries */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .convert-text1,
  .convert-text2 {
    font-size: 6rem;
  }
  .converter-parent-container {
    width: 700px;
  }
}

/* Medium devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .convert-text1,
  .convert-text2 {
    font-size: 4rem;
  }
  .converter-parent-container {
    width: 80%;
  }
}

@media (min-width: 676px) and (max-width: 767px) {
  .converter-border-container {
    flex: 0 0 auto;
    width: 600px;
    height: 250px;
  }
}

/* Small devices (landscape phones, 576px to 767px) */
@media (min-width: 576px) and (max-width: 675px) {
  .convert-text1,
  .convert-text2 {
    font-size: 3rem;
  }
  .converter-parent-container {
    width: 90%;
  }

  .converter-border-container {
    flex: 0 0 auto;
    width: 500px;
    height: 200px;
  }

  .status-text {
    color: #fdfdfd;
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Extra small devices (portrait phones, below 576px) */
@media (max-width: 575px) {
  .convert-text1,
  .convert-text2 {
    font-size: 2rem;
  }
  .converter-parent-container {
    width: 100%;
  }

  .converter-border-container {
    flex: 0 0 auto;
    width: 350px;
    height: 250px;
  }

  .status-text {
    color: #fdfdfd;
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* styles.css */
@keyframes animatedBorder {
  0% {
    border-color: rgba(231, 224, 224, 0.92);
  }
  50% {
    border-color: rgba(218, 112, 214, 0.92);
  }

  100% {
    border-color: rgba(231, 224, 224, 0.92);
  }
}

.green-background {
  background-color: rgb(6 255 0 / 43%);
}

.text-large {
  font-size: 60px;
  margin-top: 20px;
}
